import { createApi } from '@reduxjs/toolkit/query/react';

import { baseQueryWithReauth } from '@/shared/api/reauth.api';

export const hlabServiceApi = createApi({
	reducerPath: 'hlab service',
	tagTypes: [],
	baseQuery: baseQueryWithReauth(HLAB_API_URL),
	endpoints: () => ({}),
});
