import { RefObject, useEffect, useState } from 'react';

/**
 * Hook to control element width
 * @param {RefObject<T>} [ref]
 * @return {number}
 * @example
 * const ref = useRef();
 *
 * const { width } = useElementWidth(ref);
 *
 * return <Button ref={ref} />;
 */

export const useElementWidth = <T extends HTMLElement>(ref: RefObject<T>) => {
	const [width, setWidth] = useState(0);

	useEffect(() => {
		const button = ref.current;
		if (!button) return;

		const updateWidth = () => {
			setWidth(button.clientWidth);
		};

		updateWidth();

		const resizeObserver = new ResizeObserver(updateWidth);

		resizeObserver.observe(button);

		return () => resizeObserver.disconnect();
	}, []);

	return { width };
};
