import closeSelect from '@/shared/assets/icons/ui-kit/close-select-dark.png';
import openSelect from '@/shared/assets/icons/ui-kit/open-select-dark.png';

import * as RADIUS from '../../styles/radiuses';
import * as COLOR from './colors';
import { plantTheme as theme } from './theme';

export const plantLightTheme = {
	colors: {
		primary: COLOR.BLUE_1,
		secondary: COLOR.WHITE,
		tertiary: COLOR.GRAY_1,
		body: COLOR.WHITE,
		metaColor: COLOR.BLUE_2,
		...theme.colors,
	},
	input: {
		primary: {
			border: COLOR.GRAY_8,
			focus: COLOR.BLUE_1,
			fontColor: COLOR.GRAY_4,
			placeholderColor: COLOR.GRAY_2,
			background: COLOR.WHITE,
			radius: RADIUS.PX_2,
		},
		secondary: {
			border: COLOR.GRAY_10,
			focus: COLOR.BLUE_1,
			fontColor: COLOR.GRAY_4,
			placeholderColor: COLOR.GRAY_5,
			background: COLOR.GRAY_11,
			radius: RADIUS.PX_6,
		},
		...theme.input,
	},
	textarea: {
		primary: {
			fontColor: COLOR.GRAY_4,
			placeholderColor: COLOR.GRAY_6,
			background: COLOR.WHITE,
			focus: COLOR.BLUE_1,
			border: COLOR.GRAY_8,
			radius: RADIUS.PX_2,
		},
		secondary: {
			fontColor: COLOR.GRAY_6,
			placeholderColor: COLOR.GRAY_6,
			background: COLOR.DARK_6,
			focus: COLOR.BLUE_1,
			border: COLOR.GRAY_8,
			radius: RADIUS.PX_6,
		},
		...theme.textarea,
	},
	box: {
		border: COLOR.GRAY_9,
		background: COLOR.GRAY_11,
	},
	checkbox: {
		background: COLOR.BLUE_1,
		border: COLOR.GRAY_8,
	},
	logo: {
		fill: COLOR.BLUE_10,
		stroke: COLOR.BLUE_1,
	},
	page: {
		...theme.page,
		wrapper: {
			background: COLOR.WHITE,
			border: `1px solid ${COLOR.GRAY_9}`,
		},
	},
	text: {
		size: {
			...theme.text.size,
		},
		colors: {
			primary: COLOR.GRAY_1,
			secondary: COLOR.GRAY_3,
			tertiary: COLOR.GRAY_1,
			quaternary: COLOR.BLUE_12,
			quinary: COLOR.GRAY_2,
			invertedPrimary: COLOR.GRAY_11,
			invertedSecondary: COLOR.BLUE_1,
			invertedTertiary: COLOR.GRAY_4,
			invertedQuaternary: COLOR.GRAY_5,
			inherit: 'inherit',
			...theme.colors,
		},
	},
	chip: {
		background: COLOR.BLUE_10,
		borderColor: COLOR.BLUE_6,
	},
	button: {
		...theme.button,
		error: {
			background: COLOR.ERROR,
			border: '1px solid transparent',
			color: COLOR.WHITE,

			'&:hover': {
				opacity: 0.85,
			},

			'&:active': {
				opacity: 1,
			},

			'&:disabled': {
				opacity: 0.7,
				cursor: 'initial',
			},
		},
		contained: {
			background: COLOR.BLUE_1,
			border: '1px solid transparent',
			color: COLOR.WHITE,

			'&:hover': {
				background: COLOR.BLUE_2,
			},

			'&:active': {
				background: COLOR.BLUE_1,
			},

			'&:disabled': {
				background: COLOR.BLUE_5,
				cursor: 'initial',
			},
		},
		containedInverted: {
			background: COLOR.BLUE_1,
			border: '1px solid transparent',
			color: COLOR.WHITE,

			'&:hover': {
				background: COLOR.BLUE_2,
			},

			'&:active': {
				background: COLOR.BLUE_1,
			},
		},
		outlined: {
			background: 'transparent',
			color: COLOR.BLUE_1,
			border: `1px solid ${COLOR.BLUE_1}`,

			'&:hover': {
				background: COLOR.BLUE_10,
			},

			'&:active': {
				background: 'transparent',
			},

			'&:disabled': {
				border: `1px solid ${COLOR.BLUE_5}`,
				['pointer-events']: 'none',
				color: COLOR.BLUE_5,
			},
		},
		outlinedInverted: {
			background: 'transparent',
			color: COLOR.WHITE,
			border: `1px solid ${COLOR.WHITE}`,

			'&:hover': {
				background: COLOR.BLUE_2,
			},

			'&:active': {
				background: COLOR.BLUE_1,
			},
		},
		containedPrimary: {
			background: COLOR.ORANGE_1,
			border: '1px solid transparent',
			color: COLOR.WHITE,

			'&:hover': {
				background: COLOR.ORANGE_2,
			},

			'&:active': {
				background: COLOR.ORANGE_2,
			},
		},
		containedSecondary: {
			background: COLOR.BLUE_1,
			border: '1px solid transparent',
			color: COLOR.WHITE,

			'&:hover': {
				background: COLOR.BLUE_2,
			},

			'&:active': {
				background: COLOR.BLUE_1,
			},

			'&:disabled': {
				background: COLOR.BLUE_5,
				cursor: 'initial',
			},
		},
	},
	title: {
		colors: {
			primary: COLOR.GRAY_2,
			secondary: COLOR.WHITE,
		},
		...theme.title,
	},
	toggle: {
		background: COLOR.BLUE_10,
		fill: COLOR.BLUE_1,
		moon: COLOR.BLUE_1,
		sun: COLOR.WHITE,
	},
	link: {
		color: COLOR.WHITE,
		active: {
			background: COLOR.BLUE_10,
			color: COLOR.BLUE_1,
		},
	},
	avatar: {
		...theme.avatar,
	},
	layouts: {
		header: {
			...theme.layouts.header,
			background: COLOR.WHITE,
			borderBottom: `1px solid ${COLOR.GRAY_9}`,
			icon: {
				primary: COLOR.BLUE_1,
			},
		},
		sidebar: {
			...theme.layouts.sidebar,
			toggle: {
				fill: COLOR.SIDEBAR,
				stroke: COLOR.WHITE,
			},
			bottomIcon: {
				fill: COLOR.BLUE_10,
				border: COLOR.BLUE_1,
				path: COLOR.BLUE_1,
			},
			background: COLOR.SIDEBAR,
			borderRight: `1px solid ${COLOR.GRAY_9}`,
			borderBottom: `1px solid ${COLOR.GRAY_10}`,
		},
	},
	isDark: false,
	menu: {
		background: COLOR.WHITE,
		hover: COLOR.GRAY_10,
		border: `1px solid ${COLOR.GRAY_9}`,
	},
	stepper: {
		colors: {
			primary: COLOR.BLUE_1,
			disabled: COLOR.GRAY_5,
		},
		border: {
			primary: COLOR.BLUE_1,
			disabled: COLOR.GRAY_9,
		},
	},
	autocomplete: {
		...theme.autocomplete,
	},
	datepicker: {
		color: {
			primary: COLOR.DARK_1,
			scheme: 'light',
		},
		hover: '#f0f0f0',
		...theme.datepicker,
	},
	leaflet: {
		color: {
			primary: COLOR.DARK_1,
		},
	},
	option: {
		background: COLOR.WHITE,
		hover: COLOR.GRAY_10,
		border: `1px solid ${COLOR.GRAY_9}`,
		color: COLOR.GRAY_3,
	},
	select: {
		arrow: {
			open: openSelect,
			close: closeSelect,
		},
	},
	zIndex: {
		...theme.zIndex,
	},
	tabs: {
		color: COLOR.GRAY_5,
		activeColor: COLOR.BLUE_1,
		background: COLOR.WHITE,
		border: `1px solid ${COLOR.GRAY_9}`,
		...theme.tabs,
	},
	table: {
		headColor: COLOR.GRAY_1,
		bodyColor: COLOR.GRAY_3,
		background: COLOR.WHITE,
		measurementBackground: COLOR.ORANGE_10,
		headBackground: COLOR.GRAY_11,
		border: `1px solid ${COLOR.GRAY_9}`,
		offlineBackground: COLOR.GRAY_10,
		...theme.table,
	},
	toast: {
		background: COLOR.WHITE,
		closeColor: COLOR.GRAY_3,
	},
	caption: {
		primary: COLOR.GRAY_2,
		secondary: COLOR.GRAY_4,
		tertiary: COLOR.GRAY_5,
		quaternary: COLOR.GRAY_5,
		gray: COLOR.GRAY_11,
		dark: COLOR.GRAY_5,
	},
	tag: {
		border: COLOR.BLUE_6,
		radius: RADIUS.PX_5,
		background: COLOR.BLUE_10,
		color: COLOR.BLUE_1,
		...theme.tag,
	},
	list: {
		color: COLOR.GRAY_2,
	},
	container: {
		border: COLOR.GRAY_9,
		radius: RADIUS.PX_2,
	},
	section: {
		border: COLOR.GRAY_9,
		color: COLOR.GRAY_6,
	},
	breadcrumbs: {
		color: COLOR.BLUE_1,
		background: COLOR.BLUE_10,
		border: 'none',
		radius: RADIUS.PX_2,
	},
	transition: {
		...theme.transition,
	},
	modal: {
		border: COLOR.BLUE_1,
		borderInside: COLOR.GRAY_10,
		background: COLOR.WHITE,
		backgroundHeader: COLOR.BLUE_10,
		icon: COLOR.GRAY_3,
		color: COLOR.BLUE_1,
	},
	pagination: {
		color: COLOR.GRAY_5,
		background: 'transparent',
		border: `1px solid ${COLOR.GRAY_9}`,
		active: {
			background: COLOR.BLUE_10,
			color: COLOR.BLUE_1,
		},
	},
	tooltip: {
		background: COLOR.WHITE,
		hover: COLOR.GRAY_10,
		border: `1px solid ${COLOR.GRAY_9}`,
		color: {
			primary: COLOR.GRAY_1,
			secondary: COLOR.GRAY_5,
		},
	},
	widgets: {
		circle: { color: COLOR.BLUE_2, background: COLOR.BLUE_10 },
		arrow: COLOR.GRAY_1,
		addSection: {
			background: COLOR.WHITE,
		},
	},
	breakpoints: {
		...theme.breakpoints,
	},
	media: {
		...theme.media,
	},
	radio: {
		color: COLOR.GRAY_8,
		active: COLOR.BLUE_1,
	},
	spinner: {
		...theme.spinner,
		color: {
			primary: COLOR.BLUE_1,
			secondary: COLOR.WHITE,
		},
	},
	map: {
		icon: COLOR.GRAY_1,
	},
	windy: {
		icon: {
			background: COLOR.ORANGE_1,
			fill: {
				active: COLOR.DARK_1,
				unactive: COLOR.WHITE,
			},
			...theme.windy.icon,
		},
		buttons: {
			background: COLOR.GRAY_12,
			...theme.windy.buttons,
		},
	},
	dashboard: {
		icon: {
			expand: COLOR.BLUE_1,
		},
	},
	notification: {
		icon: {
			backgroundColor: COLOR.BLUE_11,
			activeColor: COLOR.BLUE_1,
		},
		card: {
			activeBackground: COLOR.BLUE_11,
			defaultBorder: 'none',
			border: 'none',
		},
		menu: {
			icon: COLOR.GRAY_1,
		},
	},
	icon: {
		roundWrapper: {
			primary: {
				backgroundColor: COLOR.BLUE_10,
				fill: COLOR.BLUE_1,
			},
			secondary: {
				backgroundColor: COLOR.GRAY_10,
				fill: COLOR.GRAY_5,
			},
		},
	},
	skeleton: {
		gradient: `linear-gradient(to right, ${COLOR.BLUE_1} 0%, ${COLOR.BLUE_2} 50%, ${COLOR.BLUE_3} 100%)`,
		shadow: COLOR.BLUE_5,
	},
	marker: {
		primary: COLOR.BLUE_1,
		secondary: COLOR.GRAY_2,
		error: COLOR.ERROR,
	},
};
