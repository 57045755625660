export enum StatusCodeError {
	Unauthorized = 401,
	NoData = 404,
	MultipleChoices = 300,
	BadRequest = 400,
	RequestEntityTooLarge = 413,
	UnprocessableEntity = 422,
	// for localhost
	FetchError = 'FETCH_ERROR',
}
