import { ChangeEvent, memo, MouseEventHandler, useCallback, useMemo } from 'react';

import { Box } from '@/shared/ui/box';

import * as Styled from './input.styles';
import { InputProps } from './input.types';

const InputComponent = ({
	label,
	onChange,
	value,
	className,
	variant = 'primary',
	type = 'text',
	Adornment,
	paragraph = '0',
	maxWidth = 'm',
	minWidth = 's',
	display = 'block',
	error = null,
	width = 'full',
	format,
	EndAdornment,
	errorBottom = '6px',
	autoComplete,
	required,
	...props
}: InputProps) => {
	const overrideOnChange = useCallback(
		(e: ChangeEvent<HTMLInputElement>) => {
			const value = e.target.value;

			if (format) {
				onChange?.(format(value));
			}
			if (!format) {
				onChange?.(e.target.value);
			}
		},
		[format, onChange],
	);

	const handleRemoveDefault: MouseEventHandler<HTMLLabelElement> = (e) => {
		e.preventDefault();
	};

	const input = useMemo(
		() => (
			<Box width={width} maxWidth={maxWidth} minWidth={minWidth} display={display}>
				<Styled.Input
					paragraph={paragraph}
					adornment={Boolean(Adornment)}
					endAdornment={Boolean(EndAdornment)}
					variant={variant}
					onChange={overrideOnChange}
					value={value}
					error={error}
					type={type}
					autoComplete={autoComplete}
					{...props}
				/>
			</Box>
		),
		[Adornment, display, error, maxWidth, minWidth, overrideOnChange, paragraph, props, type, value, variant, width],
	);

	return (
		<Styled.Wrapper className={className} onClick={handleRemoveDefault} maxWidth={maxWidth}>
			{label && (
				<Styled.Label
					label={label}
					Component={
						required && (
							<>
								{label}
								<sup>*</sup>
							</>
						)
					}
				/>
			)}
			{Adornment && <Styled.Adornment Svg={Adornment} />}
			{EndAdornment}
			{Boolean(error) && (
				<Box minWidth={minWidth} position="relative" direction="column">
					{input}
					{error?.message && <Styled.ErrorLabel bottom={errorBottom}>{error.message}</Styled.ErrorLabel>}
				</Box>
			)}
			{!error && input}
		</Styled.Wrapper>
	);
};

export default memo(InputComponent);
