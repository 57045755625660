import styled from 'styled-components';

import { Caption } from '@/shared/ui/caption';
import { Icon } from '@/shared/ui/icon';

import { InputProps, StyledAdornment } from './input.types';

export const Wrapper = styled.label<Pick<InputProps, 'maxWidth'>>`
	display: flex;
	flex: 1 1 auto;
	flex-direction: column;
	position: relative;
	max-width: ${({ maxWidth, theme: { input } }) => maxWidth && input.width[maxWidth]};
`;

export const Input = styled.input<Required<Pick<InputProps, 'variant' | 'paragraph' | 'error'> & StyledAdornment>>`
	outline: none;
	padding: ${({ adornment, endAdornment }) => {
		if (adornment) return '8px 16px 8px 42px';
		if (endAdornment) return '8px 42px 8px 16px';
		return '8px 16px';
	}};
	border: 1px solid
		${({ variant, error, theme: { input, colors } }) => (error?.show ? colors.error : input[variant].border)};
	background-color: ${({ variant, theme: { input } }) => input[variant].background};
	color: ${({ variant, theme: { input } }) => input[variant].fontColor};
	-webkit-text-fill-color: ${({ variant, theme: { input } }) => input[variant].fontColor};
	width: 100%;
	font-size: 15px;
	border-radius: ${({ variant, theme: { input } }) => input[variant].radius};
	margin-bottom: ${({ paragraph }) => paragraph};

	&:focus {
		border: 1px solid ${({ variant, theme: { input } }) => input[variant].focus};
	}

	&:disabled {
		opacity: 1;
		-khtml-opacity: 1;
		-webkit-opacity: 1;
	}
`;

export const Adornment = styled(Icon)`
	position: absolute;
	left: 17px;
	top: 25%;
`;

export const Label = styled(Caption)`
	margin-bottom: 8px;

	& sup {
		color: ${({
			theme: {
				colors: { error },
			},
		}) => error};
	}
`;

export const ErrorLabel = styled.span<{ bottom: string }>`
	position: absolute;
	bottom: ${({ bottom }) => bottom};
	left: 0;
	font-size: 12px;
	font-weight: 400;
	color: ${({ theme: { colors } }) => colors.error};
`;
