import { PayloadAction } from '@reduxjs/toolkit';

import { ContactPoint, PaginationUser } from '@/entities/user';
import { AppLanguage } from '@/shared/constants/app-language.constant';
import { AppStorage } from '@/shared/constants/app-storage.constant';
import { AppTheme } from '@/shared/constants/app-theme.constant';
import { createSlice } from '@/shared/lib/helpers/create-store-logic';

import { UserRole } from '../constants/user-role.constant';
import { User, UserSchema } from '../types/user.interface';

const initialState: UserSchema = { inited: false };

export const userInitialState: User = {
	id: '',
	username: '',
	email: '',
	role: UserRole.Admin,
	permissions: [],
	theme: AppTheme.Dark,
	language: AppLanguage.English,
	allowDashboardEdit: false,
	contactPoints: [],
	telegram: '',
	isPlant: false,
	isCloud: false,
	plantAddress: '',
	acceptedLatestAgreements: false,
	acceptedTimeTC: '',
	acceptedTimePP: '',
	dataSharing: false,
	latestAcceptedTermsAndConditionsUrl: '',
	latestPrivacyPolicyUrl: '',
	isAcceptedLatestTermsAndConditions: false,
	isAcceptedLatestPrivacyPolicy: false,
};

export const userSlice = createSlice({
	name: 'user',
	initialState,
	reducers: {
		setUser: (state, action: PayloadAction<User>) => {
			state.data = action.payload;
		},
		updateUser: (state, action: PayloadAction<PaginationUser>) => {
			state.data = {
				...state.data,
				...action.payload,
				email: action.payload.email ?? state.data?.email ?? userInitialState.email,
				theme: action.payload.theme ?? state.data?.theme ?? userInitialState.theme,
				language: action.payload.language ?? state.data?.language ?? userInitialState.language,
				allowDashboardEdit: action.payload.editDashboard ?? state.data?.allowDashboardEdit,
				permissions: state.data?.permissions ?? userInitialState.permissions,
				role: action.payload.role ?? state.data?.role ?? userInitialState.role,
				contactPoints: state.data?.contactPoints ?? userInitialState.contactPoints,
				telegram: state.data?.telegram ?? userInitialState.telegram,
				isPlant: state.data?.isPlant ?? userInitialState.isPlant,
				isCloud: state.data?.isCloud ?? userInitialState.isCloud,
				plantAddress: state.data?.plantAddress ?? userInitialState.plantAddress,
				acceptedLatestAgreements: state.data?.acceptedLatestAgreements ?? userInitialState.acceptedLatestAgreements,
				acceptedTimeTC: state.data?.acceptedTimeTC ?? userInitialState.acceptedTimeTC,
				acceptedTimePP: state.data?.acceptedTimePP ?? userInitialState.acceptedTimePP,
				dataSharing: action.payload.dataSharing ?? state.data?.dataSharing ?? userInitialState.dataSharing,
				latestAcceptedTermsAndConditionsUrl:
					state.data?.latestAcceptedTermsAndConditionsUrl ?? userInitialState.latestAcceptedTermsAndConditionsUrl,
				latestPrivacyPolicyUrl: state.data?.latestPrivacyPolicyUrl ?? userInitialState.latestPrivacyPolicyUrl,
				isAcceptedLatestTermsAndConditions:
					state.data?.isAcceptedLatestTermsAndConditions ?? userInitialState.isAcceptedLatestTermsAndConditions,
				isAcceptedLatestPrivacyPolicy:
					state.data?.isAcceptedLatestPrivacyPolicy ?? userInitialState.isAcceptedLatestPrivacyPolicy,
			};
		},
		setAccessToken: (state, action: PayloadAction<string>) => {
			state.accessToken = action.payload;
		},
		setInited: (state, action: PayloadAction<boolean>) => {
			state.inited = action.payload;
		},
		changeTheme: (state, action: PayloadAction<AppTheme>) => {
			if (state?.data?.theme) {
				state.data.theme = action.payload;
			}
		},
		changeLanguage: (state, action: PayloadAction<AppLanguage>) => {
			if (state?.data?.language) {
				state.data.language = action.payload;
			}
		},
		setContactPoints: (state, action: PayloadAction<ContactPoint[]>) => {
			if (state.data) {
				state.data.contactPoints = action.payload;
			}
		},
		setTelegram: (state, action: PayloadAction<string>) => {
			if (state.data) {
				state.data.telegram = action.payload;
			}
		},
		setDataSharing: (state, action: PayloadAction<boolean>) => {
			if (state.data) {
				state.data.dataSharing = action.payload;
			}
		},
		logout: (state) => {
			state.data = undefined;
			state.accessToken = undefined;
			Object.values(AppStorage).forEach((storageKey) => {
				localStorage.removeItem(storageKey);
			});
		},
	},
});

export const { actions: userActions, reducer: userReducer, useActions: useUserActions } = userSlice;
