import { Combobox } from '@headlessui/react';
import { Fragment, memo, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

import CloseSvg from '@/shared/assets/icons/ui-kit/close-modal.svg';
import SelectedOptionSvg from '@/shared/assets/icons/ui-kit/option-selected.svg';
import SearchIcon from '@/shared/assets/icons/ui-kit/search.svg';
import { transformAttribute } from '@/shared/lib/helpers/transform-attribute.helper';
import { useElementWidth } from '@/shared/lib/hooks/use-element-width.hook';
import { useKeyDown } from '@/shared/lib/hooks/use-key-down.hook';
import { useMatchMedia } from '@/shared/lib/hooks/use-match-media.hook';
import { useOutsideClick } from '@/shared/lib/hooks/use-outside-click.hook';
import { useSearch } from '@/shared/lib/hooks/use-search.hook';
import { Box } from '@/shared/ui/box';

import { Icon } from '../icon';
import { LongText } from '../long-text';
import { Text } from '../text';
import * as Styled from './autocomplete.styles';
import { AutocompleteProps, SearchProps } from './autocomplete.types';

const SearchComponent = memo(
	({
		onFilter,
		deferredQuery,
		removePropagation,
		variant,
		paragraph,
		Adornment,
		onClearSearch,
		...props
	}: SearchProps) => {
		useEffect(
			() => () => {
				onClearSearch();
			},
			[onClearSearch],
		);

		return (
			<Styled.SearchWrapper>
				<Styled.SearchIcon Svg={SearchIcon} />
				<Styled.SearchInput
					adornment={transformAttribute(Boolean(Adornment))}
					variant={variant}
					paragraph={paragraph}
					onChange={onFilter}
					onClick={removePropagation}
					displayValue={() => {
						if (!open) {
							return '';
						}

						return deferredQuery;
					}}
					{...props}
				/>
			</Styled.SearchWrapper>
		);
	},
);

const AutocompleteComponent = <T extends string>({
	options,
	label,
	className,
	variant = 'primary',
	Adornment,
	paragraph = '0',
	searchInOptions = false,
	searchInOptionsLabel,
	arrow = true,
	onChange,
	onSetAll,
	isAll,
	values = [],
	renderItem,
	showBeforeSearch = true,
	allowCustomInput = false,
	handleClose,
	required,
	...props
}: AutocompleteProps<T>) => {
	const {
		colors: { primary },
	} = useTheme();
	const isMobile = useMatchMedia('mobile');
	const { t } = useTranslation('common');
	const [deferredQuery, filteredOptions, onFilter, setQuery] = useSearch(options, showBeforeSearch);
	const autocompleteRef = useRef<HTMLElement>(null);
	const inputRef = useRef<HTMLInputElement>(null);
	const [input, setInput] = useState(deferredQuery);
	const optionRef = useRef(null);
	const buttonRef = useRef<HTMLButtonElement | null>(null);
	const { width } = useElementWidth(buttonRef);

	const onRemovePropagation = (e: MouseEvent) => {
		e.stopPropagation();
	};

	const handleOptionClick = (e: MouseEvent, value: string) => {
		setInput(value);
		onRemovePropagation(e);
	};

	const handleCustomInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const newValue = event.target.value as T;

		setInput(newValue);
		onFilter?.(event);

		if (allowCustomInput) {
			onChange?.(newValue);
		}
	};

	const handleCloseClick = (value: string) => (event: React.MouseEvent) => {
		event.stopPropagation();

		if (typeof handleClose === 'function') {
			handleClose(value);
		}
	};

	const handleSelectOption = (label: string) => (event: MouseEvent) => handleOptionClick(event, label);

	const onRemovePreventDefault = (e: Event) => {
		e.preventDefault();
	};

	const onClearSearch = useCallback(() => {
		setQuery('');
		if (inputRef.current) {
			inputRef.current.blur();
		}
	}, [setQuery]);

	const onOverrideOnChange = (value: T) => {
		onChange?.(value);
		onClearSearch();
	};

	useOutsideClick(autocompleteRef, onClearSearch);
	useOutsideClick(optionRef, onRemovePreventDefault);

	useKeyDown(onClearSearch);

	return (
		<Combobox ref={autocompleteRef} value={values[0] || ''} onChange={onOverrideOnChange}>
			{({ open }) => (
				<Box className={className} position="relative" direction="column">
					{label && (
						<Styled.Label
							label={label}
							Component={
								required && (
									<>
										{label}
										<sup>*</sup>
									</>
								)
							}
						/>
					)}
					{Adornment && <Styled.Adornment Svg={Adornment} />}
					{allowCustomInput && (
						<Styled.Input
							ref={inputRef}
							variant={variant}
							paragraph={paragraph}
							onChange={handleCustomInputChange}
							displayValue={() => input || values.join()}
							{...props}
						/>
					)}
					{!allowCustomInput && (
						<Styled.Button
							ref={buttonRef}
							arrow={transformAttribute(Boolean(arrow))}
							variant={variant}
							open={open}
							searchinoptions={transformAttribute(searchInOptions)}
						>
							{!searchInOptions && (
								<Styled.Input
									ref={inputRef}
									adornment={transformAttribute(Boolean(Adornment))}
									variant={variant}
									paragraph={paragraph}
									onChange={onFilter}
									open={open}
									displayValue={() => {
										if (!open) {
											return '';
										}

										return deferredQuery;
									}}
									{...props}
								/>
							)}
							{searchInOptions && (
								<LongText
									label={searchInOptionsLabel || ''}
									length={isMobile ? 25 : 36}
									flexDirection="row"
									maxWidth={`${width}px`}
									width={`${width}px`}
								/>
							)}
							{searchInOptions &&
								!searchInOptionsLabel &&
								values?.map((v) => options.find(({ value }) => v === value)?.label)}
						</Styled.Button>
					)}
					<Styled.Options
						ref={optionRef}
						border={transformAttribute(Boolean(filteredOptions.length))}
						scroll={transformAttribute(filteredOptions.length > 5)}
					>
						{searchInOptions && (
							<SearchComponent
								variant={variant}
								paragraph={paragraph}
								Adornment={Adornment}
								deferredQuery={deferredQuery}
								removePropagation={onRemovePropagation}
								onFilter={onFilter}
								onClearSearch={onClearSearch}
								{...props}
							/>
						)}
						{onSetAll && Boolean(filteredOptions.length) && (
							<Styled.Option value={t('autocomplete.all')} onClick={onSetAll}>
								{isAll ? (
									<Styled.Selected>
										<span>{t('autocomplete.all')}</span>
										<Icon Svg={SelectedOptionSvg} fill={primary} />
									</Styled.Selected>
								) : (
									<span>{t('autocomplete.all')}</span>
								)}
							</Styled.Option>
						)}
						{filteredOptions.length === 0 && deferredQuery && (
							<Styled.NoMatchesOption>
								<Text label={t('text.noMatches')} />
							</Styled.NoMatchesOption>
						)}
						{!allowCustomInput &&
							filteredOptions.map(({ value, label }) =>
								renderItem ? (
									<Fragment key={value}>{renderItem(value)}</Fragment>
								) : (
									<Styled.Option key={value} value={value} onClick={onRemovePropagation}>
										{(values as string[]).includes(value) ? (
											<Styled.Selected>
												<span>{label}</span>
												<Icon Svg={SelectedOptionSvg} fill={primary} />
											</Styled.Selected>
										) : (
											<span>{label}</span>
										)}
									</Styled.Option>
								),
							)}
						{allowCustomInput &&
							filteredOptions.map(({ value, label }) => (
								<Styled.Option key={value} value={value} onClick={handleSelectOption(label)}>
									<Styled.Selected>
										<span>{label}</span>
										{handleClose && (
											<div onClick={handleCloseClick(value)}>
												<Icon Svg={CloseSvg} fill={primary} />
											</div>
										)}
									</Styled.Selected>
								</Styled.Option>
							))}
					</Styled.Options>
				</Box>
			)}
		</Combobox>
	);
};

export default memo(AutocompleteComponent) as typeof AutocompleteComponent;
