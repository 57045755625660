import { Resource } from 'i18next';

import { AppLanguage } from '@/shared/constants/app-language.constant';

export const generateI18nResources = () => {
	const ns = [
		'aggregade',
		'agreementsSettings',
		'cockpit',
		'common',
		'dashboard',
		'helmet',
		'helmetPlant',
		'historical',
		'login',
		'management',
		'map',
		'multy',
		'notifications',
		'overview',
		'profile',
		'realtime',
		'tables',
		'users',
		'yachts',
		'plant',
		'oilAnalysis',
	];

	const supportedLngs = Object.values(AppLanguage);

	const resources = ns.reduce((acc: Resource, n) => {
		supportedLngs.forEach((lng) => {
			if (!acc[lng]) acc[lng] = {};
			acc[lng] = {
				...acc[lng],
				[n]: require(`../../../../public/locales/${lng}/${n}.json`),
			};
		});
		return acc;
	}, {});

	return { supportedLngs, resources, ns };
};
