type DateFormat = 'full' | 'date' | 'time' | 'fullForTooltip' | 'shortMonthDate';
type Separator = '-' | ':' | '/' | '';

interface DateFormatOptions {
	format: DateFormat;
	dateSeparator?: Separator;
	timeSeparator?: Separator;
	separator?: Separator;
}

export const dateFormat = (
	date: Date,
	options: DateFormatOptions = { dateSeparator: '-', timeSeparator: ':', separator: '/', format: 'full' },
) => {
	const { dateSeparator = '-', format, timeSeparator = ':', separator = '/' } = options;
	const day = date.getDate().toString().padStart(2, '0');
	const month = (date.getMonth() + 1).toString().padStart(2, '0');
	const year = date.getFullYear().toString();
	const hours = date.getHours().toString().padStart(2, '0');
	const minutes = date.getMinutes().toString().padStart(2, '0');
	const seconds = date.getSeconds().toString().padStart(2, '0');

	const shortMonthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
	const shortMonth = shortMonthNames[date.getMonth()];

	if (format === 'date') {
		return `${day}${dateSeparator}${month}${dateSeparator}${year}`;
	}

	if (format === 'time') {
		return `${hours}${timeSeparator}${minutes}${timeSeparator}${seconds}`;
	}

	if (format === 'fullForTooltip') {
		return `${day}-${month}-${year} ${hours}:${minutes}`;
	}

	if (format === 'shortMonthDate') {
		return `${day} ${shortMonth} ${year}`;
	}

	return `${day}${dateSeparator}${month}${dateSeparator}${year} ${separator} ${hours}${timeSeparator}${minutes}${timeSeparator}${seconds}`;
};
