import styled from 'styled-components';

import { flexGap } from '@/shared/lib/helpers/flex-gag-polyfill.helper';
import { Spinner } from '@/shared/ui/spinner';

import { ButtonProps } from './button.types';

export const Button = styled.button<
	Required<Pick<ButtonProps, 'variant' | 'padding' | 'size' | 'withTooltip' | 'tooltip'>>
>`
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
	border: none;
	outline: none;
	cursor: pointer;
	white-space: nowrap;
	border-radius: 2px;
	min-height: 37px;
	transition: ${({ theme: { transition } }) => transition.primary};
	padding: ${({ padding }) => padding};
	width: ${({ withTooltip, tooltip, size, theme: { button } }) =>
		withTooltip && tooltip ? button.size[size] : '100%'};
	max-width: ${({ size, theme: { button } }) => button.size[size]};
	${({ variant, theme: { button } }) => ({ ...button[variant] })};
	${flexGap('16px')};

	@media ${({ theme: { media } }) => media.mobile} {
		padding: 8px 20px;
	}
`;

export const ButtonSpinner = styled(Spinner)`
	height: auto;
	position: absolute;
	left: 10px;
`;
