import { memo } from 'react';

import { Icon } from '../icon';
import { Text } from '../text';
import { Tooltip } from '../tooltip';
import * as Styled from './button.styles';
import { ButtonProps } from './button.types';

const ButtonComponent = ({
	label,
	Svg,
	variant = 'contained',
	padding = '8px 40px',
	size = 'full',
	isLoading = false,
	disabled,
	fill,
	tooltip = '',
	withTooltip = false,
	widthTooltip,
	...props
}: ButtonProps) => {
	const renderButton = () => (
		<Styled.Button
			variant={variant}
			size={size}
			padding={padding}
			disabled={disabled || isLoading}
			withTooltip={withTooltip}
			tooltip={tooltip}
			{...props}
		>
			{isLoading && <Styled.ButtonSpinner color="secondary" size="s" margin="0" />}
			{label && <Text label={label} align="center" color="inherit" weight={500} cursor="pointer" />}
			{Svg && <Icon Svg={Svg} fill={fill} />}
		</Styled.Button>
	);

	if (withTooltip && tooltip) {
		return (
			<Tooltip direction="top" tooltip={tooltip} position="absolute" width={widthTooltip} boxSizing="border-box">
				{renderButton()}
			</Tooltip>
		);
	}

	return renderButton();
};

export default memo(ButtonComponent);
